/* eslint-disable react/no-danger */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import styles from '../Styles/CandidateResultPageContent.module.css';
import {
  CandidateEmail,
  CompanyName,
  CvCheck,
  hasAssessmentRedux,
  screeningCheckRedux,
  screeningListObjRedux,
  contentTemplates,
  assessmentIdRedux,
  userIdRedux
} from '../../../redux/AssessmentSession/selectors';

import DoubleArrowIcon from '../Images/doubleArrow.svg';
import DoubleArrowIconMobile from '../Images/doubleArrowMobile.svg';
import CvCardComponent from './CvCardComponent/CvCardComponent';
import { ASSESSMENT_FLOW_MOBILE_THRESHOLD, useWindowSize } from '../../../utils/helpers';
import {
  getAdditionalScreeningQuestionsAction,
  getAdditionalScreeningResponsesAction,
  submitAdditionalScreeningAction
} from '../Api/actions';
import ScreeningCardComponent from './Screening/ScreeningCardComponent';
import AdditionalScreeningCard from './Screening/AdditionalScreeningCard';
import AutoApplyComponent from './AutoApplyComponent/AutoApplyComponent';
import useMixpanelCandidateResultPageview from '../../../utils/mixpanelHelpers/useMixpanelCandidateResultPageview';

const CandidateResultPageContent = ({
  id,
  application,
  innerPhone,
  isAlreadyPassed,
  autoApplyStatus
}) => {
  const { t, i18n } = useTranslation(['application']);
  const [width] = useWindowSize();

  const [path, setPath] = useState(null);
  const [cardState, setCardState] = useState(1);
  const [screeningCardState, setScreeningCardState] = useState(1);
  const [additionalCardState, setAdditionalCardState] = useState(0);
  const [showAdditionalScreening, setShowAdditionalScreening] = useState(false);
  const [additionalQuestions, setAdditionalQuestions] = useState([]);
  const [additionalResponses, setAdditionalResponses] = useState([]);

  const assessmentId = useSelector(assessmentIdRedux);
  const screeningCheck = useSelector(screeningCheckRedux);
  const cvCheck = useSelector(CvCheck);
  const userId = useSelector(userIdRedux);

  const companyName = useSelector(CompanyName);
  const hasAssessmentFlag = useSelector(hasAssessmentRedux);

  const contentTemplatesRedux = useSelector(contentTemplates);

  const getContentText = sourceType => {
    return contentTemplatesRedux.find(content => content.sourceType === sourceType);
  };

  const [cvComponentSavedState, setCvComponentSavedState] = useState(false);
  const candidateEmail = useSelector(CandidateEmail);

  /* ------------------------- Screening related methods -------------------------*/
  const questionListObj = useSelector(screeningListObjRedux);
  const [screeningCompleted, setScreeningCompleted] = useState(false);

  useEffect(() => {
    if (screeningCardState === 1) {
      setAdditionalCardState(0);
    }
  }, [screeningCardState]);

  useEffect(() => {
    if (assessmentId) {
      fetchAdditionalQuestions(assessmentId);
    }
  }, [assessmentId]);

  const fetchAdditionalQuestions = async companyAssessmentId => {
    await getAdditionalScreeningQuestionsAction({ companyAssessmentId }).then(resp => {
      if (resp.data && resp.data.questions.length > 0) {
        setAdditionalQuestions(resp.data.questions);
        setShowAdditionalScreening(true);
      }
    });
  };

  const fetchAdditionalResponses = async (companyAssessmentId, userId) => {
    await getAdditionalScreeningResponsesAction({ companyAssessmentId, userId }).then(resp => {
      if (resp.data) {
        setAdditionalResponses(resp.data);
      }
    });
  };

  const handleAdditionalScreeningSubmit = async responses => {
    const data = {
      userId,
      companyAssessmentId: assessmentId,
      responses
    };

    await submitAdditionalScreeningAction(data)
      .then(resp => {
        if (resp.data) {
          setAdditionalCardState(0);
          setScreeningCompleted(true);
          setShowAdditionalScreening(false);
        }
      });
  };

  useEffect(() => {
    if (userId && assessmentId) {
      fetchAdditionalResponses(assessmentId, userId);
    }
  }, [userId, assessmentId]);

  /* ------------------------- Screening related methods -------------------------*/

  /* ------------------------- Mixpanel related methods -------------------------*/
  useMixpanelCandidateResultPageview(application, isAlreadyPassed, autoApplyStatus);
  /* ------------------------- Mixpanel related methods -------------------------*/

  const SHOW_SCREENING = screeningCheck && questionListObj && !screeningCompleted;
  const SHOW_APPLICATION_TEXTS = !SHOW_SCREENING && !showAdditionalScreening;
  const SHOW_CV_UPLOAD = !SHOW_SCREENING && cvCheck;
  const SHOW_AUTO_APPLY = !SHOW_SCREENING && cvCheck && autoApplyStatus !== undefined;
  const SHOW_CONTACT_TEXT = !SHOW_SCREENING && !cvCheck && !showAdditionalScreening;

  useEffect(() => {
    if (!screeningCheck && !cvCheck) {
      setAdditionalCardState(1);
      setCardState(0);
    }
  }, [screeningCheck, cvCheck]);

  return (
    <>
      {SHOW_SCREENING && (
        <ScreeningCardComponent
          closeScreening={() => {
            if (showAdditionalScreening) {
              setScreeningCardState(0);
              setAdditionalCardState(1);
            } else {
              setScreeningCompleted(true);
            }
          }}
          application={application}
          screeningCardState={screeningCardState}
          setScreeningCardState={setScreeningCardState}
          showAdditionalScreening={showAdditionalScreening}
        />
      )}

      {showAdditionalScreening && additionalQuestions.length > 0 && (
        <AdditionalScreeningCard
          companyName={companyName}
          questions={additionalQuestions}
          additionalCardState={additionalCardState}
          handleSubmit={handleAdditionalScreeningSubmit}
          additionalResponses={additionalResponses}
        />
      )}

      {SHOW_APPLICATION_TEXTS &&
        (application ? (
          i18n.language === 'tr' ? (
            <>
              <div className={styles.title}>{getContentText(2).titleTr}</div>
              {hasAssessmentFlag && (
                <div
                  className={styles.subtitle}
                  dangerouslySetInnerHTML={{
                    __html: getContentText(2).subtitleTr
                  }}
                />
              )}
            </>
          ) : (
            <>
              <div className={styles.title}>{getContentText(2).titleEn}</div>
              {hasAssessmentFlag && (
                <div
                  className={styles.subtitle}
                  dangerouslySetInnerHTML={{
                    __html: getContentText(2).subtitleEn
                  }}
                />
              )}
            </>
          )
        ) : i18n.language === 'tr' ? (
          <>
            <div className={styles.title}>{getContentText(1).titleTr}</div>
            <div
              className={styles.subtitle}
              dangerouslySetInnerHTML={{
                __html: getContentText(1).subtitleTr
              }}
            />
          </>
        ) : (
          <>
            <div className={styles.title}>{getContentText(1).titleEn}</div>
            <div
              className={styles.subtitle}
              dangerouslySetInnerHTML={{
                __html: getContentText(1).subtitleEn
              }}
            />
          </>
        ))}

      {SHOW_CV_UPLOAD && (
        <CvCardComponent
          id={id}
          cardState={cardState}
          setCardState={setCardState}
          path={path}
          setPath={setPath}
          innerPhone={innerPhone}
          setParentSavedState={setCvComponentSavedState}
          application={application}
          autoApplyStatus={autoApplyStatus}
        />
      )}

      {SHOW_AUTO_APPLY && (
        <div className={styles.NewOpWrapper}>
          <div className={styles.NewOpInfoWrapper}>
            <div className={styles.NewOpHeaderInfoTextWrapper}>
              <div className={styles.DoubleArrowIconWrapper}>
                <img
                  src={
                    width < ASSESSMENT_FLOW_MOBILE_THRESHOLD
                      ? DoubleArrowIconMobile
                      : DoubleArrowIcon
                  }
                  alt="doubleArrowIcon"
                />
              </div>
              <div className={styles.NewOpInfoHeader}>
                {width <= ASSESSMENT_FLOW_MOBILE_THRESHOLD
                  ? t('autoApplyInfoHeaderMobile')
                  : t('autoApplyInfoHeaderWeb')}
              </div>
              <div className={styles.DoubleArrowIconWrapper}>
                <img
                  src={
                    width < ASSESSMENT_FLOW_MOBILE_THRESHOLD
                      ? DoubleArrowIconMobile
                      : DoubleArrowIcon
                  }
                  alt="doubleArrowIcon"
                />
              </div>
            </div>
          </div>

          <AutoApplyComponent
            status={autoApplyStatus}
            assessmentKey={id}
            candidateEmail={candidateEmail}
          />
        </div>
      )}

      {SHOW_CONTACT_TEXT && companyName !== 'BT Akademi' && companyName !== 'Turkcell Akademi' && (
        <div className={styles.subtitle}>{t('contactText')}</div>
      )}

      {SHOW_APPLICATION_TEXTS &&
        ((!cardState && (cvComponentSavedState || path)) || !cvCheck) &&
        companyName !== 'Turkcell Akademi' && (
          <div className={styles.closeWindow}>
            <span>{companyName === 'Brisa' ? t('closeWindowBrisa') : t('closeWindow')}</span>
          </div>
        )}
    </>
  );
};

CandidateResultPageContent.propTypes = {
  id: PropTypes.string.isRequired,
  application: PropTypes.bool,
  innerPhone: PropTypes.string,
  isAlreadyPassed: PropTypes.string,
  autoApplyStatus: PropTypes.bool.isRequired
};

CandidateResultPageContent.defaultProps = {
  application: false,
  innerPhone: '',
  isAlreadyPassed: ''
};

export default CandidateResultPageContent;

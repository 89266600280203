import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    debug: false,
    fallbackLng: 'tr',
    ns: ['application'],
    interpolation: {
      escapeValue: false,
      format: function (value, format) {
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'lowercase') return value.toLowerCase();
        if (format === 'capitalize') return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
        return value;
      }
    }
  });

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation, Trans } from 'react-i18next';
import styled from 'styled-components';
import VerificationInput from 'react-verification-input';
import { useLocation } from 'react-router-dom';
import {
  CandidateEmail,
  AssessmentTitle,
  CompanyName
} from '../../../../redux/AssessmentSession/selectors';
import styles from './OTPVerification.module.css';
import EmailText from '../../../../Common/Components/EmailText';
import CustomButton from '../../../../Common/Components/CustomButton';
import CustomPhoneInput from '../../../../Common/Components/CustomPhoneInput/PhoneInput';
import { useIp } from '../../../../hooks/useIp';
import { ASSESSMENT_FLOW_MOBILE_THRESHOLD, useWindowSize } from '../../../../utils/helpers';
import {
  setInfoBannerShowState,
  setInfoBannerText,
  setInfoBannerErrorFlag
} from '../../../../redux/assessmentDetailVol2/slice';
import {
  sendVerificationCodeAction,
  updatePhoneNumberAction,
  validateVerificationCodeAction,
  sendEmailVerificationCodeAction
} from '../../Api/actions';

const OTPVerification = ({ setPhoneNumber, setIsVerified, setOtpCode }) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [width] = useWindowSize();

  const email = useSelector(CandidateEmail);
  const reduxAssessmentName = useSelector(AssessmentTitle);
  const companyName = useSelector(CompanyName);
  const assessmentId = new URLSearchParams(search).get('assessment');
  const [locationInfo] = useIp();

  const [step, setStep] = useState();
  const [phone, setPhone] = useState(null);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(120);
  const [verificationCode, setVerificationCode] = useState('');
  const [getNewCode, setGetNewCode] = useState(false);
  const [isExist, setIsExist] = useState(false);
  const [codeError, setCodeError] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { t } = useTranslation(['otpVerification']);

  useEffect(() => {
    if (email) {
      checkTimer();
    }
  }, [email]);

  useEffect(() => {
    setCodeError(null);
  }, [verificationCode]);

  useEffect(() => {
    setVerificationCode('');
    setCodeError(null);
  }, [step]);

  const fetchSavedPhoneNumber = async email => {
    setLoading(true);
    await sendVerificationCodeAction({
      email,
      companyAssessmentId: assessmentId
    })
      .then(res => {
        if (res.status === 200 && res.data.success) {
          dispatch(setInfoBannerText(t('codeSentToPhone')));
          dispatch(setInfoBannerShowState(true));
          if (res.data.phone.startsWith('+')) {
            setPhone(res.data.phone);
          } else {
            setPhone(`+${res.data.phone}`);
          }
          setIsExist(true);
          setStep(2);
          setTimer(120);
          localStorage.setItem(
            'otpSettings',
            JSON.stringify({
              step: 2,
              phone: res.data.phone,
              timer: new Date().getTime() + 120000,
              verificationCode: null,
              isVerified: false
            })
          );
        } else if (res.status === 200 && res.data.phone && !res.data.success) {
          dispatch(setInfoBannerErrorFlag(true));
          dispatch(setInfoBannerText(t('tryAgain')));
          dispatch(setInfoBannerShowState(true));
          setStep(5);
        } else if (res.message) {
          dispatch(setInfoBannerErrorFlag(true));
          dispatch(setInfoBannerText(res.message));
          dispatch(setInfoBannerShowState(true));
          setErrorMessage(res.message);
          setStep(5);
        } else {
          setStep(1);
          localStorage.setItem(
            'otpSettings',
            JSON.stringify({
              step: 1,
              phone: null,
              timer: null,
              verificationCode: null,
              isVerified: false
            })
          );
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetCode = async () => {
    setLoading(true);
    setCodeError(null);
    await updatePhoneNumberAction({
      email,
      phone: phone.slice(1),
      isExist,
      companyAssessmentId: assessmentId
    })
      .then(res => {
        if (res.status === 200) {
          dispatch(setInfoBannerText(t('codeSuccessSent')));
          dispatch(setInfoBannerShowState(true));
          setStep(2);
          setTimer(120);
          localStorage.setItem(
            'otpSettings',
            JSON.stringify({
              step: 2,
              phone: phone,
              timer: new Date().getTime() + 120000,
              verificationCode: null,
              isVerified: false
            })
          );
        } else {
          dispatch(setInfoBannerErrorFlag(true));
          dispatch(setInfoBannerText(res.message));
          dispatch(setInfoBannerShowState(true));
        }
      })
      .catch(() => {
        dispatch(setInfoBannerErrorFlag(true));
        dispatch(setInfoBannerText(t('phoneNumberCannotUsed')));
        dispatch(setInfoBannerShowState(true));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSendCode = async verificationType => {
    setLoading(true);
    setCodeError(null);
    await validateVerificationCodeAction({
      email,
      phone: phone ? (phone.includes('*') ? null : phone.slice(1)) : null,
      verificationCode: verificationCode.toUpperCase(),
      verificationType,
      companyAssessmentId: assessmentId,
      verificationContext: 1
    })
      .then(res => {
        if (verificationType === 1 && res.status === 200) {
          dispatch(setInfoBannerText(t('codeSuccessSent')));
          dispatch(setInfoBannerShowState(true));
          localStorage.setItem(
            'otpSettings',
            JSON.stringify({
              step: null,
              phone: phone,
              timer: new Date().getTime() + 120000,
              verificationCode: verificationCode.toUpperCase(),
              isVerified: true
            })
          );
          setIsVerified(true);
          setPhoneNumber(phone.includes('*') ? null : phone.slice(1));
          setOtpCode(verificationCode.toUpperCase());
        } else if (verificationType === 2 && res.status === 200) {
          dispatch(setInfoBannerText(t('verificationSuccess')));
          dispatch(setInfoBannerShowState(true));
          localStorage.setItem(
            'otpSettings',
            JSON.stringify({
              step: 1,
              phone: null,
              timer: new Date().getTime() + 120000,
              verificationCode: null,
              isVerified: false
            })
          );
          setStep(1);
        } else {
          dispatch(setInfoBannerErrorFlag(true));
          dispatch(setInfoBannerText(res.message));
          dispatch(setInfoBannerShowState(true));
          setCodeError(res.message);
        }
      })
      .catch(() => {
        setErrorMessage(t('errorVerification'));
        localStorage.setItem(
          'otpSettings',
          JSON.stringify({
            step: 5,
            phone: phone,
            timer: new Date().getTime() + 120000,
            verificationCode: null,
            isVerified: false
          })
        );
        setStep(5);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleGetNewCode = async () => {
    setLoading(true);
    setGetNewCode(false);
    setCodeError(null);
    await updatePhoneNumberAction({
      email,
      phone: phone.slice(1),
      isExist,
      companyAssessmentId: assessmentId
    })
      .then(res => {
        if (res.status === 200) {
          dispatch(setInfoBannerText(t('codeSuccessSent')));
          dispatch(setInfoBannerShowState(true));
          localStorage.setItem(
            'otpSettings',
            JSON.stringify({
              step: 2,
              phone: phone,
              timer: new Date().getTime() + 120000,
              verificationCode: null,
              isVerified: false
            })
          );
          setVerificationCode('');
          setTimer(120);
        } else {
          dispatch(setInfoBannerErrorFlag(true));
          dispatch(setInfoBannerText(res.message));
          dispatch(setInfoBannerShowState(true));
        }
      })
      .catch(() => {
        dispatch(setInfoBannerErrorFlag(true));
        dispatch(setInfoBannerText(t('phoneNumberCannotUsed')));
        dispatch(setInfoBannerShowState(true));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChangePhoneNumber = async () => {
    if (isExist) {
      await sendEmailVerificationCodeAction({
        email,
        companyAssessmentId: assessmentId,
        verificationContext: 1
      }).then(res => {
        if (res.data) {
          dispatch(setInfoBannerText(t('codeSentToMail')));
          dispatch(setInfoBannerShowState(true));
          localStorage.setItem(
            'otpSettings',
            JSON.stringify({
              step: 3,
              phone: null,
              timer: new Date().getTime() + 120000,
              verificationCode: null,
              isVerified: false
            })
          );
          setTimer(120);
          setStep(3);
          setPhone(null);
        } else if (res.message) {
          dispatch(setInfoBannerErrorFlag(true));
          dispatch(setInfoBannerText(res.message));
          dispatch(setInfoBannerShowState(true));
        }
      });
    } else {
      setStep(1);
      setPhone(null);
      localStorage.setItem(
        'otpSettings',
        JSON.stringify({
          step: 1,
          phone: null,
          timer: new Date().getTime() + 120000,
          verificationCode: null,
          isVerified: false
        })
      );
    }
  };

  // prechecked phone steps
  const checkTimer = () => {
    const otpSettings = JSON.parse(localStorage.getItem('otpSettings'));
    if (otpSettings) {
      const currentTime = new Date().getTime();
      const remainingTime = Math.floor((otpSettings.timer - currentTime) / 1000);
      if (remainingTime > 0) {
        setStep(otpSettings.step);
        setPhone(otpSettings.phone);
        setTimer(remainingTime);
        setVerificationCode(otpSettings.verificationCode);
        if (otpSettings.isVerified) {
          setPhoneNumber(otpSettings.phone);
          setOtpCode(otpSettings.verificationCode);
        }
        setIsVerified(otpSettings.isVerified);
      } else {
        localStorage.removeItem('otpSettings');
        fetchSavedPhoneNumber(email);
      }
    } else {
      fetchSavedPhoneNumber(email);
    }
  };

  useEffect(() => {
    let interval;
    if ((timer > 0 && step === 2) || step === 3) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setGetNewCode(true);
    }
    return () => clearInterval(interval);
  }, [step, timer]);

  return (
    <div className={styles.container}>
      <CardWrapper>
        <BigCard>
          <InnerCard>
            <div className={styles.otpContainer}>
              {loading ? (
                <span
                  style={{
                    width: '100%',
                    textAlign: 'center'
                  }}
                >
                  {t('loading')}
                </span>
              ) : (
                <>
                  {width > ASSESSMENT_FLOW_MOBILE_THRESHOLD && (
                    <span className={styles.otpHeader}>{companyName}</span>
                  )}
                  <span className={styles.otpEmail}>{reduxAssessmentName}</span>
                  <div className={styles.inputContainer}>
                    {step === 1 ? (
                      <>
                        <span className={styles.otpDescription}>{t('verifyNumber')}</span>
                        <EmailText
                          inputChange={() => {}}
                          value={email}
                          placeholder={t('email')}
                          isDisabled
                          trimWhiteSpaces
                        />
                        <CustomPhoneInput
                          country={locationInfo?.country || 'TR'}
                          phoneNum={phone}
                          setPhoneNum={e => setPhone(e)}
                        />
                        <CustomButton
                          style={{ width: '100%', marginTop: '3.4rem' }}
                          buttonOnClick={handleGetCode}
                          loading={loading}
                          type={1}
                          isDisabled={!phone || phone?.length < 10}
                          buttonType="button"
                          textField={t('getCode')}
                        />
                      </>
                    ) : step === 2 ? (
                      <>
                        {!getNewCode ? (
                          <>
                            <span className={styles.otpDescription}>
                              <Trans
                                i18nKey="enterCode"
                                ns="otpVerification"
                                values={{ phone: phone }}
                              >
                                0
                                <span className={styles.phoneText}>
                                  <b>1</b>
                                </span>
                                <span>2</span>
                              </Trans>
                            </span>
                            <span className="">
                              <VerificationInput
                                value={verificationCode.toUpperCase()}
                                length={6}
                                placeholder=""
                                inputProps={{
                                  disabled: timer === 0 || loading
                                }}
                                validChars="A-Za-z0-9çÇğĞıİiöÖşŞüÜ"
                                onChange={e => {
                                  const normalizedValue = e
                                    .replace(/ç|Ç/g, 'c')
                                    .replace(/ğ|Ğ/g, 'g')
                                    .replace(/ı|I|i|İ/g, 'i')
                                    .replace(/ö|Ö/g, 'o')
                                    .replace(/ş|Ş/g, 's')
                                    .replace(/ü|Ü/g, 'u');

                                  setVerificationCode(normalizedValue);
                                }}
                                classNames={{
                                  container: styles.verificationInputContainer,
                                  character: codeError
                                    ? styles.verificationInputCharacterError
                                    : timer !== 0
                                    ? styles.verificationInputCharacter
                                    : styles.verificationInputCharacterDisabled,
                                  characterInactive: styles.verificationInputCharacterInactive,
                                  characterSelected: styles.verificationInputCharacterSelected,
                                  characterFilled: styles.verificationInputCharacterFilled
                                }}
                              />
                              {codeError && (
                                <span className={styles.errorText}>{t('codeIncorrect')}</span>
                              )}
                            </span>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <div>{t('newCodeTime')}&nbsp;</div>
                              <span>
                                {Math.floor(timer / 60)}:{timer % 60 < 10 ? '0' : ''}
                                {timer % 60}
                              </span>
                            </div>
                            <CustomButton
                              style={{ width: '100%', marginTop: '2rem' }}
                              buttonOnClick={() => handleSendCode(1)}
                              loading={loading}
                              type={1}
                              isDisabled={
                                !verificationCode || verificationCode?.length < 6 || timer === 0
                              }
                              buttonType="button"
                              textField={t('verify')}
                            />
                            <CustomButton
                              style={{ width: '100%' }}
                              buttonOnClick={handleChangePhoneNumber}
                              type={4}
                              isDisabled={loading}
                              buttonType="button"
                              textField={isExist ? t('didChangeNummber') : t('changeNumber')}
                            />
                          </>
                        ) : (
                          <>
                            <span className={styles.otpDescription}>
                              <Trans
                                i18nKey="codeExpired"
                                ns="otpVerification"
                                values={{ phone: phone }}
                              >
                                0
                                <span className={styles.phoneText}>
                                  <b>1</b>
                                </span>
                                <span>2</span>
                              </Trans>
                            </span>
                            <CustomButton
                              style={{ width: '100%' }}
                              buttonOnClick={handleGetNewCode}
                              type={1}
                              isDisabled={!getNewCode || loading}
                              buttonType="button"
                              textField={t('sendNewCode')}
                            />
                            <CustomButton
                              style={{ width: '100%' }}
                              buttonOnClick={handleChangePhoneNumber}
                              type={4}
                              isDisabled={loading}
                              buttonType="button"
                              textField={isExist ? t('didChangeNummber') : t('changeNumber')}
                            />
                          </>
                        )}
                      </>
                    ) : step === 3 ? (
                      <>
                        <>
                          <span className={styles.otpDescription}>
                            <Trans
                              i18nKey="codeSentMail"
                              ns="otpVerification"
                              values={{ email: email }}
                            >
                              0
                              <span className={styles.phoneText}>
                                <b>1</b>
                              </span>
                              <span>2</span>
                            </Trans>
                          </span>
                          <span className="">
                            <VerificationInput
                              value={verificationCode.toUpperCase()}
                              length={6}
                              placeholder=""
                              inputProps={{
                                disabled: timer === 0 || loading
                              }}
                              validChars="A-Za-z0-9çÇğĞıİiöÖşŞüÜ"
                              onChange={e => {
                                const normalizedValue = e
                                  .replace(/ç|Ç/g, 'c')
                                  .replace(/ğ|Ğ/g, 'g')
                                  .replace(/ı|I|i|İ/g, 'i')
                                  .replace(/ö|Ö/g, 'o')
                                  .replace(/ş|Ş/g, 's')
                                  .replace(/ü|Ü/g, 'u');

                                setVerificationCode(normalizedValue);
                              }}
                              classNames={{
                                container: styles.verificationInputContainer,
                                character: codeError
                                  ? styles.verificationInputCharacterError
                                  : timer !== 0
                                  ? styles.verificationInputCharacter
                                  : styles.verificationInputCharacterDisabled,
                                characterInactive: styles.verificationInputCharacterInactive,
                                characterSelected: styles.verificationInputCharacterSelected,
                                characterFilled: styles.verificationInputCharacterFilled
                              }}
                            />
                            {codeError && (
                              <span className={styles.errorText}>{t('codeIncorrect')}</span>
                            )}
                          </span>

                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div>{t('newCodeTime')}&nbsp;</div>
                            <span>
                              {Math.floor(timer / 60)}:{timer % 60 < 10 ? '0' : ''}
                              {timer % 60}
                            </span>
                          </div>

                          <CustomButton
                            style={{ width: '100%', marginTop: '2rem' }}
                            buttonOnClick={() => handleSendCode(2)}
                            loading={loading}
                            type={1}
                            isDisabled={
                              !verificationCode || verificationCode?.length < 6 || timer === 0
                            }
                            buttonType="button"
                            textField={t('verify')}
                          />
                        </>
                      </>
                    ) : step === 5 ? (
                      <>
                        {errorMessage && (
                          <span className={styles.otpDescription}>{errorMessage}</span>
                        )}
                        <span className={styles.otpDescription}>{t('tryAgain')}</span>
                      </>
                    ) : null}
                  </div>
                </>
              )}
            </div>
          </InnerCard>
        </BigCard>
      </CardWrapper>
    </div>
  );
};

const CardWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    justify-content: flex-start;
    padding: 2.4rem;
    padding-top: 28px;
  }
`;

const BigCard = styled.div`
  max-width: 500px; // Margin should be bigger if there screen is bigger
  min-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px; // If less change to mobile version
  background: #ffffff;
  display: flex;
  margin-left: 2rem;
  width: 60%; // To try the continuous scaling
  margin-right: 2rem;
  flex-direction: column;
  padding: 3.2rem;
  justify-content: center;
  box-shadow: 0px 1px 24px rgba(131, 131, 131, 0.1);

  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    width: 100%;
    max-width: none; // Bunlar nasıl daha iyi yapılır
    max-height: none;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    min-width: 0;
    box-shadow: none;
  }
`;

const InnerCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
`;

export default OTPVerification;

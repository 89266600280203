import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import styles from '../Styles/WelcomeCandidateContent.module.css';
import CustomButton from '../../Common/Components/CustomButton';
import {
  AssessmentTitle,
  CompanyName,
  Expired,
  LogoPath,
  rootAssessmentNameRedux,
  DescriptionEN,
  DescriptionTR,
  PositionHeaderEN,
  PositionHeaderTR
} from '../../redux/AssessmentSession/selectors';
import {
  applyCandidateToAssessmentAction,
  candidateHasDataAction
} from '../../redux/AssessmentSession/actions';
import { setApplicationState, setCandidateEmail } from '../../redux/AssessmentSession/slice';
import isEmail from '../../utils/isEmail';
import mockImage from '../../images/Common/Coensio_newLogo.svg';
import EmailText from '../../Common/Components/EmailText';
import coensioCircleLogo from '../Images/coensioCircleLogo.svg';
import { ASSESSMENT_FLOW_MOBILE_THRESHOLD } from '../../utils/helpers';

import mixpanel from '../../utils/mixpanel';
import { getUserAutoApplyAction } from '../../Assessment/Test/Api/actions';
import UnpublishedAutoApplyComponent from '../../Assessment/Test/Components/AutoApplyComponent/UnpublishedAutoApplyComponent';
import OopsComponent from './UnpublishedAssessment/OopsComponent';

const OOPS_FLAG = 'oops';

const LeftColumn = ({ logoPath, descriptionEN, descriptionTR }) => {
  const { t, i18n } = useTranslation(['application']);

  return (
    <>
      <div className={styles.leftColumn}>
        <img
          className={styles.icon}
          alt=""
          src={logoPath ? `${process.env.REACT_APP_IMAGE_API}/${logoPath}` : mockImage}
        />
        <div className={styles.heading}>{t('leftColumnMobileHeader')}</div>
        <div
          className={`${styles.text} ${styles.centerText}`}
          dangerouslySetInnerHTML={{
            __html: i18n.language === 'tr' ? descriptionTR : descriptionEN
          }}
        />
      </div>
    </>
  );
};

const RightColumn = ({
  assessmentTitle,
  companyName,
  id,
  logoPath,
  application,
  setAutoApplyStatusForExpired,
  setIsMailEnteredForExpiredFlag,
  rootAssessmentName,
  positionHeaderEN,
  positionHeaderTR
}) => {
  const { t, i18n } = useTranslation(['application']);

  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const expired = useSelector(Expired);
  const { search } = useLocation();

  const emailFromUrl = new URLSearchParams(search).get('email');
  const arriveType = new URLSearchParams(search).get('ArriveType');
  const kariyernetToken = new URLSearchParams(search).get('ats_token');

  function getUtmFromWelcomeCandidate() {
    const url = window.location.href;
    const array = url.split('&');
    const utmArray = [];
    array?.forEach(x => {
      if (x.includes('utm')) {
        utmArray.push(x);
      }
    });
    let utmUrl = '';
    if (utmArray.length > 0) {
      utmUrl = utmArray.join('&');
    }
    return utmUrl;
  }

  // Mixpanel variable
  const [candidateData, setCandidateData] = useState();

  const fetchNewOpsForExpired = async () => {
    if (emailFromUrl) {
      mixpanel.track('Welcome Candidate - Continue Button Click', {
        'Assessment Name': assessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': companyName,
        'Candidate Email': emailFromUrl,
        'Is Mail Encrypted': true
      });
      await dispatch(setCandidateEmail(emailFromUrl));
    } else {
      await dispatch(setCandidateEmail(email));
    }

    const data = {
      Email: emailFromUrl || email,
      CompanyAssessmentKey: id
    };
    const resp = await getUserAutoApplyAction(data);

    if (resp?.status === 200) {
      const respData = resp?.data;
      setAutoApplyStatusForExpired(respData?.status);
    } else {
      setAutoApplyStatusForExpired(OOPS_FLAG);
    }

    if (application) {
      mixpanel.track('Application - Apply Button Success', {
        'Assessment Name': assessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': companyName,
        'Candidate Email': email,
        'Already Scored Flag': candidateData?.payload?.isAlreadyPassed,
        'CV Flag': !!candidateData?.payload?.cvPath
      });
    } else {
      mixpanel.track('Welcome Candidate - Continue Button Success', {
        'Assessment Name': assessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': companyName,
        'Candidate Email': emailFromUrl || email,
        'Is Mail Encrypted': !!emailFromUrl,
        'Already Scored Flag': candidateData?.payload?.isAlreadyPassed,
        'CV Flag': !!candidateData?.payload?.cvPath
      });
    }

    setIsMailEnteredForExpiredFlag(true);
  };

  useEffect(() => {
    if (emailFromUrl && expired) {
      fetchNewOpsForExpired();
    }
  }, []);

  const onClick = async () => {
    setLoading(true);

    if (application) {
      mixpanel.track('Application - Apply Button Click', {
        'Assessment Name': assessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': companyName,
        'Candidate Email': email
      });
    } else {
      mixpanel.track('Welcome Candidate - Continue Button Click', {
        'Assessment Name': assessmentTitle,
        'Root Assessment Name': rootAssessmentName,
        'Company Name': companyName,
        'Candidate Email': email,
        'Is Mail Encrypted': false
      });
    }

    if (kariyernetToken) {
      localStorage.setItem('kariyernetToken', kariyernetToken);
    }

    localStorage.setItem('companyName', companyName);

    if (!expired) {
      await dispatch(setCandidateEmail(email));
      const jsonData = { Key: id, Email: email };
      const firstResp = await dispatch(candidateHasDataAction(jsonData));
      localStorage.setItem('isCandidateExist', firstResp?.payload?.userId);

      if (firstResp?.meta?.requestStatus === 'fulfilled') {
        setCandidateData(firstResp);
        const passed = firstResp.payload.isAlreadyPassed;
        localStorage.setItem('isCandidateAlreadyPassed', passed);

        let applicationSuccess = true;
        if (application) {
          const appData = { email, companyAssessmentKey: id };
          const resp = await dispatch(applyCandidateToAssessmentAction(appData));
          if (resp.meta.requestStatus === 'fulfilled') {
            applicationSuccess = true;
            mixpanel.track('Application - Apply Button Success', {
              'Assessment Name': assessmentTitle,
              'Root Assessment Name': rootAssessmentName,
              'Company Name': companyName,
              'Candidate Email': email,
              'Already Scored Flag': passed,
              'CV Flag': !!firstResp?.payload?.cvPath
            });
          } else {
            applicationSuccess = false;
            mixpanel.track('Application - Apply Button Fail', {
              'Assessment Name': assessmentTitle,
              'Root Assessment Name': rootAssessmentName,
              'Company Name': companyName,
              'Candidate Email': email,
              'Already Scored Flag': passed,
              'CV Flag': !!firstResp?.payload?.cvPath,
              'Error message': resp?.payload
            });
          }
        }

        if (passed && !application) {
          mixpanel.track('Welcome Candidate - Continue Button Success', {
            'Assessment Name': assessmentTitle,
            'Root Assessment Name': rootAssessmentName,
            'Company Name': companyName,
            'Candidate Email': email,
            'Is Mail Encrypted': false,
            'Already Scored Flag': passed,
            'CV Flag': !!firstResp?.payload?.cvPath
          });
          history.push(`/candidate-result?assessment=${id}`);
        } else if (!application) {
          mixpanel.track('Welcome Candidate - Continue Button Success', {
            'Assessment Name': assessmentTitle,
            'Root Assessment Name': rootAssessmentName,
            'Company Name': companyName,
            'Candidate Email': email,
            'Is Mail Encrypted': false,
            'Already Scored Flag': passed,
            'CV Flag': !!firstResp?.payload?.cvPath
          });
          const utms = getUtmFromWelcomeCandidate();
          history.push(
            `/assessment-session-information?assessment=${id}&${utms}${
              arriveType ? `&ArriveType=${arriveType}` : ''
            }`
          );
        } else if (application && applicationSuccess) {
          dispatch(setApplicationState(`/candidate-result?assessment=${id}`));
        }
      } else {
        /* eslint-disable-next-line */
        if (application) {
          mixpanel.track('Application - Apply Button Fail', {
            'Assessment Name': assessmentTitle,
            'Root Assessment Name': rootAssessmentName,
            'Company Name': companyName,
            'Candidate Email': email,
            'Error message': 'Error on fetching candidate data'
          });
        } else {
          mixpanel.track('Welcome Candidate - Continue Button Fail', {
            'Assessment Name': assessmentTitle,
            'Root Assessment Name': rootAssessmentName,
            'Company Name': companyName,
            'Candidate Email': email,
            'Is Mail Encrypted': false,
            'Error message': 'Error on fetching candidate data'
          });
        }
      }
    }

    if (expired) {
      await fetchNewOpsForExpired();
    }

    setLoading(false);
  };

  return (
    <>
      <div className={styles.rightColumn}>
        <div className={styles.ImageWrapper}>
          <img
            alt=""
            src={logoPath ? `${process.env.REACT_APP_IMAGE_API}/${logoPath}` : mockImage}
            className={styles.rightIcon}
          />
          <img className={styles.coensioLogo} src={coensioCircleLogo} alt="" />
        </div>

        <div
          className={styles.assessmentTitle}
          style={{ marginBottom: '1rem' }}
          dangerouslySetInnerHTML={{
            __html: i18n.language === 'tr' ? positionHeaderTR : positionHeaderEN
          }}
        >
          {/* <Trans
            i18nKey={
              application
                ? 'rightSideHeaderApplication'
                : companyName === 'Turkish Airlines'
                ? 'rightSideHeaderTestForThy'
                : 'rightSideHeaderTest'
            }
            values={{
              companyName: kariyernetToken ? '' : companyName,
              assessmentTitle: assessmentTitle
            }}
          >
            Apply {{ companyName }} as <br />
            <strong className="fw-500">{{ assessmentTitle }}</strong>
          </Trans> */}
        </div>

        <div className={styles.lowerColumn}>
          <div>
            <div className={styles.emailTitle}>{t('enterEmail')}</div>
            <EmailText
              inputChange={event => {
                if (isEmail(event.target.value.toLocaleLowerCase('tr'))) {
                  setError(false);
                } else {
                  setError(true);
                }
              }}
              value={email}
              setValue={setEmail}
              placeholder={t('email')}
              onEnterDown={
                !error && email
                  ? () => {
                      onClick();
                    }
                  : () => {}
              }
              isDisabled={loading}
              trimWhiteSpaces
            />
          </div>
          <div>
            <CustomButton
              style={{ width: '100%', marginTop: '3.4rem' }}
              buttonOnClick={() => {
                onClick();
              }}
              loading={loading}
              type={1}
              isDisabled={!email || error}
              buttonType="button"
              textField={application ? t('apply') : t('continue')}
            />
          </div>
          {application ? (
            <span className="fw-300 font-12 mt-4 c-darkGray">
              <Trans i18nKey="applicationKvkkText">
                By clicking the &quot;Apply&quot; button, you agree to the Coensio
                <a
                  className="fw-400"
                  href="https://coens.io/privacy-policy/"
                  target="__BLANK"
                  style={{ color: '#7458D3' }}
                >
                  Privacy Policy
                </a>
                and
                <a
                  className="fw-400"
                  href="https://coens.io/terms-of-use/"
                  target="__BLANK"
                  style={{ color: '#7458D3' }}
                >
                  Terms Of Use
                </a>
                .
              </Trans>
            </span>
          ) : null}
        </div>
      </div>
    </>
  );
};

const WelcomeCandidateContent = ({ id, application = false }) => {
  const logoPath = useSelector(LogoPath);
  const companyName = useSelector(CompanyName);
  const assessmentTitle = useSelector(AssessmentTitle);
  const rootAssessmentName = useSelector(rootAssessmentNameRedux);

  const descriptionEN = useSelector(DescriptionEN);
  const descriptionTR = useSelector(DescriptionTR);
  const positionHeaderEN = useSelector(PositionHeaderEN);
  const positionHeaderTR = useSelector(PositionHeaderTR);

  const [isMailEntered, setIsMailEntered] = useState(false);
  const [autoApplyStatus, setAutoApplyStatus] = useState(undefined);

  return (
    <>
      {isMailEntered && autoApplyStatus !== undefined && autoApplyStatus !== OOPS_FLAG ? (
        <UnpublishedAutoApplyComponent
          autoApplyStatus={autoApplyStatus}
          id={id}
          application={application}
        />
      ) : isMailEntered && autoApplyStatus === OOPS_FLAG ? (
        <OopsComponent />
      ) : (
        <PageWrapper>
          <CardWrapper>
            <BigCard>
              <InnerCard>
                <LeftColumn
                  descriptionEN={descriptionEN}
                  descriptionTR={descriptionTR}
                  logoPath={logoPath}
                />
                <RightColumn
                  application={application}
                  logoPath={logoPath}
                  id={id}
                  companyName={companyName}
                  assessmentTitle={assessmentTitle}
                  setAutoApplyStatusForExpired={setAutoApplyStatus}
                  setIsMailEnteredForExpiredFlag={setIsMailEntered}
                  rootAssessmentName={rootAssessmentName}
                  positionHeaderEN={positionHeaderEN}
                  positionHeaderTR={positionHeaderTR}
                />
              </InnerCard>
            </BigCard>
          </CardWrapper>
          <Powered>
            <div className={styles.powered}>powered by</div>
            <img style={{ maxHeight: '1.4rem' }} alt="" src={mockImage} />
          </Powered>
        </PageWrapper>
      )}
    </>
  );
};

export default WelcomeCandidateContent;

const PageWrapper = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  flex-direction: column;
  position: relative;
  justify-content: center;
  height: 100%;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    background-color: white;
    justify-content: space-between;
  }
`;

const CardWrapper = styled.div`
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    justify-content: flex-start;
    padding: 2.4rem;
    padding-top: 28px;
  }
`;

const BigCard = styled.div`
  max-width: 1000px; // Margin should be bigger if there screen is bigger
  min-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px; // If less change to mobile version
  background: #ffffff;
  display: flex;
  margin-left: 2rem;
  width: 60%; // To try the continuous scaling
  margin-right: 2rem;
  flex-direction: column;
  padding: 3.2rem;
  justify-content: center;
  box-shadow: 0px 1px 24px rgba(131, 131, 131, 0.1);

  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    width: 100%;
    max-width: none; // Bunlar nasıl daha iyi yapılır
    max-height: none;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    min-width: 0;
    box-shadow: none;
  }
`;

const InnerCard = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
`;

const Powered = styled.div`
  display: flex;
  max-width: 1000px; // Margin should be bigger if there screen is bigger
  min-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px; // If less change to mobile version
  width: 60%;
  margin-top: 1.6rem;
  flex-direction: row;
  margin-right: auto;
  margin-left: auto;
  @media screen and (max-width: ${ASSESSMENT_FLOW_MOBILE_THRESHOLD}px) {
    display: none;
  }
  align-items: center;
`;

const CANDIDATE_ARRIVE_TYPES = {
  Other: 0,
  Email: 10, // Invite by email from Assessment detail page "send assessment link" button
  LinkedIn: 20,
  KariyerIntegration: 30,
  WelcomePageLink: 40, // welcome-candidate page
  ApplicationLink: 50, // application page -> email -> start assessment
  SuccessFactors: 60, // success factors
  AlreadySolvedTransfer: 70, // apply_1 -> apply_2 -> solve_2 -> automaitcly transfer score to application1
  NewOpsNewOpenings: 80,
  NewOpsFewApplicants: 90,
  NewOpsPopulars: 100,
  CandidateAutoApply: 110,
  KariyerAts: 120
};

const lng = localStorage.getItem('i18nextLng');

export default {
  MOBILE_MIN_SUPPORT_THRESHOLD: 350,
  MOBILE_MID_SUPPORT_THRESHOLD: 475,
  MOBILE_LARGE_SUPPORT_THRESHOLD: 530,
  MOBILE_THRESHOLD: 576,
  ASSESSMENT_CUSTOMIZATION_THRESHOLD: 710,

  // REQUEST_CANDIDATE_OBJECT_STATUS: 9,
  NEWOP_LOCKED_CANDIDATE_OBJECT_STATUS: 4,
  // REQUEST_STATUS_NEW: 10,
  // REQUEST_STATUS_PENDING: 20,
  // REQUEST_STATUS_ACCEPTED: 30,
  // REQUEST_STATUS_DECLINED: 40,
  HTTP_RESPONSE_CODE_MAX_REQUEST: 418,

  QUESTION_TYPE_ID: {
    1: 'Multiple Select',
    2: 'Coding',
    3: 'Free Text',
    4: 'Multiple Correct'
  },
  QUESTION_TYPE_MULTIPLE_CHOICE: 1,
  QUESTION_TYPE_CODING: 2,
  QUESTION_TYPE_FREE_TEXT: 3,
  QUESTION_TYPE_MULTIPLE_CORRECT: 4,

  HIRING_FUNNEL_LIST: [{ customFunnelName: 'All Candidates', priority: 1 }],
  NEW_LIST: [
    { customFunnelName: 'Not Scored', priority: 1 },
    { customFunnelName: 'Scored', priority: 2 }
  ],
  CONCLUDED_LIST: [
    { customFunnelName: 'Hired', priority: 1 },
    { customFunnelName: 'Rejected', priority: 2 }
  ],

  TRANSLATE_CHECK_ASSESSMENT_KEYS: [
    'da232323-bb0d-462c-975f-18ee9da8509c',
    'faeeb8e2-f38b-48f6-b213-33aa5466a6e9',
    'a52ef8ce-67d7-441b-b3b5-15cd867ca970',
    'cdc3a80d-5830-48e2-85a6-175b8679b9bf',
    'c0fd7fe9-5e38-4e1e-9d90-799f70be8c68',
    '79b2f802-f42f-4993-b50e-c9019a528539',
    '9405d8da-1554-4135-be14-842b37da37c3',
    '0041a0b6-917d-454e-a9cc-bd3472320129',
    '864c0d4e-d424-487c-a2a7-64b236cadb69',
    '871dc48e-d275-4e91-a8e4-55471e077154',
    '05d3fb08-c34a-4ee4-803a-54a61eef37e8',
    'ec3bf69a-3d9d-4754-9050-02f8f64f8560',
    '78285965-7e7c-43d6-a779-6d9465e8f625',
    '8acd9971-a4a4-49f8-ab78-f24e17329b2e',
    '8edc8198-eb3b-41b3-bb86-2ce254fd1767',
    'a486f4a5-c4fc-40a9-9af9-dd0d16b33f88',
    'beb5e63c-0bce-4e29-93c0-94e797a0abca',
    '49cddc3e-72d7-434a-8da3-c5e288a9095d',
    '3a07d382-2a3f-4ee9-b2d5-a3bd3a82081e',
    'd25143cf-ed93-4837-ad5b-204b9e073c22',
    '2322bc59-abc9-45df-9b9f-fc4f4f4b889c', // Turkcell ui-ux
    'eabda088-2d05-438a-9546-31fd31305c88', // Turkcell Java
    'a0cf88e2-a3a3-45fe-8422-8950f165424a', // Turkcell Frontend
    '1bbfecf9-5621-4031-8f1e-0b374da1cc3c', // Bilim İlaç 1
    '7fed6830-4697-4fbf-a349-fb216cfe6d67', // Bilim İlaç 2
    '2938f3c9-2bf0-4e8e-ab28-c94ba4f84597', // Bilim İlaç 3
    '5bc95a8c-1b58-47f4-93b1-256f88e60c16',
    'a3a4151b-8b4c-49f4-a7a5-4d0cbd89d12c', // Bilim İlac 4
    'f6c146c0-1309-42ba-8349-7a2fec812a56', // Bilim İlac 5
    '44ff8d4d-ea2d-4e91-b6cf-bfb6b58994d6', // Turkcell geceği yazan kadınlar
    'c433a494-5933-4efe-a1db-6993f1e34f1d', // Teknolojide Güvenlik Akademisi Genel Yetenek Testi
    '2c1d2014-0422-4cce-8679-2a1355ba445d', // Teknolojide Güvenlik Akademisi Yabancı Dil Testi
    'fa45bb28-f123-474d-8b1a-ce2195bc9d93', // Teknolojide Güvenlik Akademisi Teknik Test
  ],
  DISABLE_CAMERA_COMPANY_ASSESSMENT_NAME_LIST: ['BAL'],
  SHOW_PUBLIC_REPORT_BUTTON: ['Netaş', 'Nesine.com'],

  SAVE_FILTER_ARRIVE_TYPE_INDEX_MAPPING: {
    0: [CANDIDATE_ARRIVE_TYPES.CandidateAutoApply], // 0 is for Applied via Coensio
    1: [CANDIDATE_ARRIVE_TYPES.Email], // 1 is for Invite by Email
    2: [
      CANDIDATE_ARRIVE_TYPES.Other,
      CANDIDATE_ARRIVE_TYPES.LinkedIn,
      CANDIDATE_ARRIVE_TYPES.KariyerIntegration,
      CANDIDATE_ARRIVE_TYPES.WelcomePageLink,
      CANDIDATE_ARRIVE_TYPES.ApplicationLink,
      CANDIDATE_ARRIVE_TYPES.SuccessFactors,
      CANDIDATE_ARRIVE_TYPES.AlreadySolvedTransfer,
      CANDIDATE_ARRIVE_TYPES.KariyerAts
    ] // 2 is for Other
  },

  CANDIDATE_ARRIVE_TYPES: CANDIDATE_ARRIVE_TYPES
};

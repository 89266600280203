import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD } from '../../../../utils/helpers';
import { ReactComponent as Close } from '../../../../images/Candidate/TestPages/resultCloseButton.svg';
import styles from './AdditionalScreeningCard.module.css';
import AdditionalSingleSelect from './AdditionalSingleSelect';
import AdditionalMultiSelect from './AdditionalMultiSelect';
import AdditionalFreeText from './AdditionalFreeText';
import CustomButton from '../../../../Common/Components/CustomButton';

const CLOSED = 0;

const AdditionalScreeningCard = ({
  companyName,
  questions,
  additionalCardState,
  handleSubmit,
  additionalResponses,
}) => {
  const { t } = useTranslation(['application']);

  const [responses, setResponses] = useState([]);

  useEffect(() => {
    if (additionalResponses) {
      const newResponses = [];
      additionalResponses.forEach(response => {
        if (response.selectedOptionId !== undefined) {
          newResponses.push({
            questionId: questions.find(q => q.title === response.title).questionId,
            selectedOptionId: response.selectedOptionId
          });
        } else {
          newResponses.push({
            questionId: questions.find(q => q.title === response.title).questionId,
            responseText: response.responseText
          });
        }
      });
      setResponses(newResponses);
    }
  }, [additionalResponses]);

  const handleSingleSelect = (questionId, selectedOptionId) => {
    const newResponses = responses.filter(response => response.questionId !== questionId);

    if (selectedOptionId === null) {
      setResponses(newResponses);
      return;
    }

    newResponses.push({
      questionId,
      selectedOptionId: questions
        .find(q => q.questionId === questionId)
        .options.findIndex(o => o === selectedOptionId)
    });
    setResponses(newResponses);
  };

  const handleMultiSelect = (questionId, selectedOptionIds) => {
    const newResponses = responses.filter(response => response.questionId !== questionId);
    if (selectedOptionIds.length === 0) {
      setResponses(newResponses);
      return;
    }

    newResponses.push({ questionId, responseText: JSON.stringify(selectedOptionIds) });
    setResponses(newResponses);
  };

  const handleFreeText = (questionId, responseText) => {
    const newResponses = responses.filter(response => response.questionId !== questionId);

    if (responseText === '') {
      setResponses(newResponses);
      return;
    }

    newResponses.push({ questionId, responseText });
    setResponses(newResponses);
  };

  const isAllMandatoryAnswered = questions
    .filter(question => question.isMandatory) // Sadece isMandatory true olan soruları filtreliyoruz
    .every(
      mandatoryQuestion =>
        responses.some(response => response.questionId === mandatoryQuestion.questionId) // Bu soruların hepsine bir cevap var mı kontrol ediyoruz
    );

  return (
    <div>
      <div className={styles.title}>
        <span style={{ fontWeight: '700', fontSize: '1.8rem' }}>{companyName}</span> wants to know
        these👇🏼
      </div>
      <ExtendableCard>
        <div className={styles.header}>
          <div>
            {additionalCardState === CLOSED && (
              <div>Additional Screening bilgilerinizi güncelleyebilirsiniz.</div>
            )}
          </div>
          <Close
            className={`${styles.baseIcon} ${
              additionalCardState === CLOSED ? styles.closedIcon : styles.openIcon
            }`}
          />
        </div>

        <div
          className={`${styles.baseInputBody} ${
            additionalCardState === CLOSED ? styles.closedInputBody : styles.openInputBody
          } `}
        >
          {questions.map((question, index) =>
            question.questionType === 10 ? (
              <AdditionalSingleSelect
                key={index}
                question={question}
                handleSingleSelect={handleSingleSelect}
                previousResponse={
                  additionalResponses?.find(response => response.title === question.title)
                    ?.selectedOptionId
                }
              />
            ) : question.questionType === 20 ? (
              <AdditionalMultiSelect
                key={index}
                question={question}
                handleMultiSelect={handleMultiSelect}
                previousResponse={
                  additionalResponses?.find(response => response.title === question.title)
                    ?.responseText
                }
              />
            ) : question.questionType === 40 ? (
              <AdditionalFreeText
                key={index}
                question={question}
                handleFreeText={handleFreeText}
                previousResponse={
                  additionalResponses?.find(response => response.title === question.title)
                    ?.responseText
                }
              />
            ) : null
          )}

          <div className={styles.buttonWrapper}>
            <CustomButton
              textField={t('screeningSaveButton')}
              buttonOnClick={() => {
                handleSubmit(responses);
              }}
              isDisabled={!isAllMandatoryAnswered}
            />
          </div>
        </div>
      </ExtendableCard>
    </div>
  );
};

const ExtendableCard = styled.div`
  background-color: white;
  margin-top: 2.4rem;
  box-shadow: 0px 1px 24px rgba(208, 208, 208, 0.15);
  border-radius: 12px;
  width: 100%;
  padding-bottom: 2.4rem;
  padding-top: 2.4rem;
  position: relative;
  padding-left: 3.2rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  overflow: hidden;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-right: 3.2rem;
  overflow: hidden;

  @media screen and (max-width: ${ASSESSMENT_FLOW_CODING_MOBILE_THRESHOLD}px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
`;

export default AdditionalScreeningCard;
